export default {
  general: {
    username: 'Nome utente',
    password: 'Password',
    newpass: 'Nuova password',
    currentpass: 'Password attuale',
    c_password: 'Conferma la password',
    c_newpass: 'Conferma la nuova password',
    email: 'Email',
    language: 'Lingua',
    subject: 'Oggetto',
    message: 'Messaggio',
    filtername: 'Nome del filtro',
    placeholders: {
      username: 'Inserisci nome utente',
      password: 'Inserisci password',
      newpass: 'Inserisci la nuova password',
      currentpass: 'Inserisci la password attuale',
      c_password: 'Conferma la password',
      c_newpass: 'Conferma la nuova password',
      email: 'Inserisci l\'indirizzo email',
      subject: 'Inserisci l\'oggetto',
      message: 'Inserisci il messaggio',
      filtername: 'Inserisci un nome'
    },
    errors: {
      language: {
        load: 'Impossibile cambiare lingua.'
      }
    },
    here: 'qui',
    ok: 'Ok',
    cancel: 'Annulla',
    on: 'on',
    off: 'off',
    min: 'Valore minimo',
    max: 'Valore massimo',
    years: '{n} anno | {n} anni',
    days: '{n} giorno | {n} giorni',
    type_for_more: 'Digita per vedere un elenco...'
  },
  notifications: {
    disabled: 'Le {notifications} sono bloccate. Se non le hai bloccate, prova a utilizzare un altro browser.',
    notifications: 'notifiche',
    title: 'Impostazioni delle Notifiche',
    form: {
      email: 'Notifiche via email',
      email_frequency: 'Frequenza delle notifiche via email',
      browser: 'Notifiche del browser',
      browser_frequency: 'Frequenza delle notifiche del browser',
      frequency: 'istantanee | una volta ogni {n} ora | una volta ogni {n} ore',
      help: {
        email: 'Riceverai le notifiche relative ai giocatori via email.',
        browser: 'Riceverai le notifiche relative ai giocatori direttamente nel browser.',
        frequency: 'La scelta raccomandata è \'istantanee\', per evitare di perdere qualche giocatore.',
        blocked: 'Hai bloccato le notifiche oppure il tuo browser non le supporta.'
      }
    }
  },
  header: {
    menu: {
      home: 'Pagina Principale',
      account: 'Profilo',
      signin: 'Accedi',
      register: 'Registrati',
      inbox: 'Inbox',
      blog: 'Blog',
      contact: 'Contattaci',
      logout: 'Esci'
    }
  },
  footer: {
    menu: {
      privacy: 'Privacy (Inglese)',
      terms: 'Termini di Servizio (Inglese)'
    }
  },
  login: {
    title: 'Accesso',
    signin: 'Accedi',
    register: 'Registrati',
    forgot: 'Password dimenticata?',
    form: {
      submit: 'Accedi',
      remember: 'Resta collegato'
    },
    info: {
      what: 'A cosa serve Mercattrick?',
      purpose: 'Gli obiettivi di Mercattrick sono quelli di:',
      purpose1: 'aiutare gli <strong>utenti</strong> di Hattrick a dare più visibilità ai giocatori che <strong>vendono</strong>, ottenendo così un prezzo migliore;',
      purpose2: 'aiutare gli <strong>utenti</strong> di Hattrick <strong>interessati a comprare</strong> alcuni tipi di giocatori, inviando loro notifiche quando giocatori di loro interesse vengono aggiunti alla lista trasferimenti;',
      how: 'Come funziona?',
      buyers: 'I compratori',
      step1: 'si collegano e impostano le caratteristiche minime dei giocatori (filtri) per i quali vogliono ricevere notifiche una volta messi in vendita.',
      sellers: 'I venditori',
      step2: ', una volta messo in lista trasferimenti un giocatore su HT, si collegano qui e "promuovono" il loro giocatore.',
      promoting: 'Promuovere',
      step3: 'un giocatore, comporta che tutti i "compratori" interessati a quel tipo di giocatore saranno avvertiti che è stato messo in vendita.'
    }
  },
  register: {
    title: 'Registrati',
    form: {
      registered: 'Il tuo account è stato creato. Clicca {here} per continuare.',
      submit: 'Registrati',
      agreement: 'Cliccando @:register.form.submit dichiari di accettare i {terms} e l\'{privacy} e di ricevere occasionalmente emails relative al tuo account.',
      tos: 'termini di servizio',
      privacy: 'informativa sulla privacy',
      help: {
        username: 'Questo non è il nome utente di HT.',
        password: 'Inserisci un minimo 6 caratteri. La password distingue maiuscole e minuscole.',
        email: 'Useremo questo indirizzo email per inviarti notifiche sui giocatori.'
      }
    }
  },
  recover: {
    title: 'Recupero Password',
    form: {
      recovered: 'Ti abbiamo mandato un\'email contenente un link per cambiare la password.',
      submit: 'Recupera',
      help: {
        email: 'Questo indirizzo email deve essere quello associato al tuo account.'
      }
    }
  },
  reset: {
    title: 'Reimposta Password',
    form: {
      submit: 'Reimposta',
      help: {
        email: 'Questo indirizzo email deve essere quello associtato al tuo account.',
        password: 'Inserisci un minimo 6 caratteri. La password distingue maiuscole e minuscole.'
      }
    }
  },
  contact: {
    title: 'Contattaci',
    form: {
      success: 'Il messaggio è stato correttamente inviato.',
      submit: 'Invia',
      help: {
        email: 'Useremo questo indirizzo email solo per inviarti risposte.'
      }
    }
  },
  sync: {
    users: {
      syncing: 'Sto sincronizzando i dettagli dell\'utente...',
      fail: 'La sincronizzazione dei dati non è andata a buon fine.',
      retry: 'Riprova',
      authorize: 'Devi autorizzare questo prodotto CHPP. Clicca {here} per essere reindirizzato al sito di Hattrick.',
      authorizing: 'Sto inizializzando l\'autorizzazione. Sarai reindirizzato al sito <strong>hattrick.org</strong> a breve...',
      finalize: 'Sto completando l\'autorizzazione...'
    },
    system: {
      syncing: 'Sto sincronizzando il sistema...',
      fail: 'La sincronizzazione del sistema non è andata a buon fine. {retry} or {skip}!',
      retry: 'Riprova',
      skip: 'Ignora'
    }
  },
  account: {
    account: {
      title: 'Impostazioni Account',
      form: {
        success: 'L\'account è stato aggiornato con successo.',
        submit: 'Salva',
        help: {
          username: 'Questo non è il nome utente in HT.',
          email: 'Useremo questo indirizzo email per inviarti notifiche sui giocatori.'
        }
      }
    },
    login: {
      title: 'Impostazioni di Login',
      form: {
        success: 'La password è stata aggiornata con successo.',
        submit: 'Salva',
        help: {
          passwords: 'Inserisci la password attuale, la nuova password e la conferma della nuova password.'
        }
      }
    },
    privacy: {
      title: 'Impostazioni sulla Privacy',
      form: {
        submit: 'Cancella l\'account',
        delete: 'Cancella',
        help: {
          warning: 'Quest\'azione sarà permanente. Tutti i tuoi dati personali verranno cancellati.',
          confirmation: 'Per favore, conferma che vuoi cancellare definitivamente il tuo account.'
        }
      }
    }
  },
  filter: {
    title: 'Tocca per aggiungere/rimuovere un filtro',
    form: {
      submit: 'Salva',
      set: 'Imposta',
      remove: 'Cancella',
      help: {
        name: 'Dai un nome al filtro per identificarlo.'
      }
    },
    tooltip: {
      save: 'Devi impostare almeno un criterio per il filtro!'
    },
    item: {
      tooltip: 'Clicca \'@:filter.item.show_players\' per visualizzare la lista.',
      show_players: 'Visualizza giocatori',
      hide_players: 'Nascondi giocatori',
      players: 'giocatore trovato | giocatori trovati',
      form: {
        processing: 'elaborazione',
        fetch: 'Trova giocatori in HT',
        edit: 'Modifica',
        delete: 'Cancella',
        help: {
          confirm: 'Sei sicuro di voler cancellare questo filtro?'
        }
      }
    }
  },
  home: {
    myplayers: {
      title: 'I Miei Trasferimenti',
      loading: 'Sto caricando i giocatori...',
      none: 'Al momento, non ci sono giocatori in vendita.',
      promoted: 'promosso',
      late: 'non promuovibile, troppo tardi',
      form: {
        reload: 'Ricarica',
        promote: 'Promuovi'
      },
      tooltip: 'Promuovi i tuoi giocatori per renderli visibili agli altri.'
    },
    bookmarks: {
      title: 'Segnalibri',
      loading: 'Sto caricando i giocatori...',
      none: 'Al momento, non hai giocatori nei segnalibri.',
      form: {
        reload: 'Ricarica',
        unbookmark: 'Rimuovi',
        help: {
          confirm: 'Sei sicuro di voler cancellare questo segnalibro?'
        }
      },
      tooltip: 'Riceverai notifiche relative a questi giocatori quando saranno vicini alla scadenza.'
    },
    filters: {
      title: 'I Miei Filtri',
      loading: 'Sto caricando i filtri...',
      none: 'Non hai nessun filtro. Dovresti crearne uno.',
      form: {
        reload: 'Ricarica',
        add: 'Aggiungi un filtro',
        bookmark: 'Segnalibro',
        hide: 'Nascondi',
        help: {
          confirm: 'Sei sicuro di voler nascondere questo giocatore?'
        }
      },
      tooltip: 'Aggiungi ai segnalibri i giocatori di queste liste che ti interessano.'
    }
  },

  player: {
    attributes: {
      name: 'Nome',
      age: 'Età',
      form: 'Forma',
      experience: 'Esperienza',
      leadership: 'Carisma',
      stamina_skill: 'Resistenza',
      keeper_skill: 'Parate',
      playmaker_skill: 'Regia',
      scorer_skill: 'Attacco',
      passing_skill: 'Passaggi',
      winger_skill: 'Cross',
      defender_skill: 'Difesa',
      set_pieces_skill: 'Calci piazzati',
      agreeability: 'Simpatia',
      aggressiveness: 'Aggressività',
      honesty: 'Condotta morale',
      htms: 'HTMS',
      htms28: 'HTMS28',
      tsi: 'TSI',
      salary: 'Stipendio',
      price: 'Prezzo',
      asking_price: 'Prezzo richiesto',
      highest_bid: 'Offerta più alta',
      native_country: 'Paese d\'origine',
      native_country_id: 'Paese d\'origine',
      NT_player: 'Giocatore convocato in nazionale',
      specialties: 'Specialità',
      specialty: 'Specialità'
    },
    redcard: 'Cartellino rosso',
    yellowcard: 'Cartellino giallo',
    bruised: 'Acciaccato',
    injured: 'Infortunato',
    injury: '{n} settimana | {n} settimane',
    fullage: '{years} anni e {days} giorni',
    deadline: 'Scadenza',
    htms: 'HTMS/HTMS28',
    more: 'Espandi',
    less: 'Riduci',
    levels: {
      skill: [
        'inesistente',
        'disastroso',
        'tremendo',
        'scarso',
        'debole',
        'insufficiente',
        'accettabile',
        'buono',
        'eccellente',
        'formidabile',
        'straordinario',
        'splendido',
        'magnifico',
        'fuoriclasse',
        'sovrannaturale',
        'titanico',
        'extraterrestre',
        'mitico',
        'magico',
        'utopico',
        'divino',
        'divino (+1)',
        'divino (+2)',
        'divino (+3)',
        'divino (+4)',
        'divino (+5)'
      ],
      agreeability: [
        'attaccabrighe',
        'scontrosa',
        'gradevole',
        'simpatica',
        'popolare',
        'amata'
      ],
      aggressiveness: [
        'tranquilla',
        'calma',
        'equilibrata',
        'impulsiva',
        'irascibile',
        'lunatica'
      ],
      honesty: [
        'infame',
        'disonesta',
        'onesta',
        'retta',
        'virtuosa',
        'quasi santa'
      ],
      specialty: [
        'Senza Specialità',
        'Tecnico',
        'Veloce',
        'Potente',
        'Imprevedibile',
        'Colpo di testa ',
        'Coriaceo',
        '',
        'Trascinatore'
      ],
      national_team: [
        'mai',
        'attualmente no',
        'attualmente sì'
      ],
      any: 'tutto/senza'
    },
    tooltips: {
      highest_bid: 'L\'offerta più alta potrebbe non essere aggiornata!'
    }
  },
  countries: {
    1: 'Svezia',
    2: 'Inghilterra',
    3: 'Germania',
    4: 'Italia',
    5: 'Francia',
    6: 'Messico',
    7: 'Argentina',
    8: 'USA',
    9: 'Norvegia',
    10: 'Danimarca',
    11: 'Finlandia',
    12: 'Paesi Bassi',
    13: 'Oceania',
    14: 'Canada',
    15: 'Scozia',
    16: 'Irlanda',
    17: 'Cile',
    18: 'Colombia',
    19: 'Uruguay',
    20: 'Venezuela',
    21: 'Perù',
    22: 'Brasile',
    23: 'Portogallo',
    24: 'Sud Africa',
    25: 'Giappone',
    26: 'Polonia',
    27: 'India',
    28: 'Cina',
    29: 'Corea del Sud',
    30: 'Thailandia',
    31: 'Turchia',
    32: 'Egitto',
    33: 'Austria',
    34: 'Russia',
    35: 'Spagna',
    36: 'Romania',
    37: 'Islanda',
    38: 'Belgio',
    39: 'Malesia',
    40: 'Svizzera',
    41: 'Singapore',
    42: 'Croazia',
    43: 'Serbia',
    44: 'Ungheria',
    45: 'Grecia',
    46: 'Repubblica Ceca',
    47: 'Estonia',
    48: 'Lettonia',
    49: 'Indonesia',
    50: 'Filippine',
    51: 'Israele',
    52: 'Taiwan',
    53: 'Hong Kong',
    55: 'Bulgaria',
    56: 'Galles',
    57: 'Slovenia',
    61: 'Lituania',
    62: 'Ucraina',
    63: 'Bosnia ed Erzegovina',
    64: 'Pakistan',
    65: 'Vietnam',
    66: 'Slovacchia',
    67: 'Paraguay',
    68: 'Ecuador',
    69: 'Bolivia',
    70: 'Nigeria',
    71: 'Isole Faroe',
    72: 'Morocco',
    75: 'Arabia Saudita',
    76: 'Tunisia',
    77: 'Costa Rica',
    78: 'Emirati Arabi',
    79: 'Lussemburgo',
    80: 'Iran',
    82: 'Cipro',
    83: 'Repubblica Dominicana',
    86: 'Senegal',
    87: 'Bielorussia',
    88: 'Irlanda del Nord',
    89: 'Giamaica',
    90: 'Kenya',
    91: 'Panama',
    92: 'Repubblica di Macedonia',
    93: 'Cuba',
    94: 'Albania',
    95: 'Honduras',
    96: 'El Salvador',
    97: 'Malta',
    98: 'Kirghizistan',
    99: 'Moldavia',
    100: 'Georgia',
    101: 'Andorra',
    102: 'Guatemala',
    103: 'Giordania',
    104: 'Armenia',
    105: 'Trinidad e Tobago',
    121: 'Nicaragua',
    122: 'Kazakistan',
    123: 'Suriname',
    125: 'Liechtenstein',
    126: 'Algeria',
    127: 'Mongolia',
    128: 'Libano',
    129: 'Bahrein',
    130: 'Barbados',
    131: 'Capo Verde',
    132: 'Costa d’Avorio',
    133: 'Azerbaigian',
    134: 'Kuwait',
    135: 'Iraq',
    136: 'Montenegro',
    137: 'Angola',
    138: 'Bangladesh',
    139: 'Yemen',
    140: 'Oman',
    142: 'Mozambico',
    143: 'Brunei',
    144: 'Ghana',
    145: 'Cambogia',
    147: 'Benin',
    148: 'Siria',
    149: 'Qatar',
    150: 'Tanzania',
    153: 'Uganda',
    154: 'Maldive',
    163: 'Uzbekistan',
    165: 'Camerun',
    166: 'Palestine',
    175: 'Sri Lanka',
    177: 'São Tomé e Príncipe',
    178: 'Curaçao',
    179: 'Guam',
    180: 'Comore',
    181: 'RD del Congo',
    182: 'Etiopia',
    183: 'Madagascar',
    184: 'Saint Vincent e Grenadine',
    185: 'Botswana',
    186: 'Belize',
    187: 'Zambia',
    188: 'Haiti',
    189: 'Myanmar',
    190: 'Porto Rico',
    191: 'San Marino',
    192: 'Nepal',
    193: 'Burkina Faso',
    194: 'Granada',
    196: 'Tahiti',
    197: 'Guyana'
  },

  validation: {
    attributes: {},
    messages: {
      alpha_num: 'Questo campo può contenere solo caratteri alfanumerici.',
      alpha_dash: 'Questo campo può contenere solo caratteri alfanumerici, - o _.',
      confirmed: 'La conferma non corrisponde.',
      email: 'L\'indirizzo email deve essere valido.',
      max: 'Questo campo contiene troppi caratteri.',
      min: 'Questo campo non contiene abbastanza caratteri.',
      required: 'Questo campo è necessario.'
    }
  },

  api: {
    validation: {
      string: {
        subject: 'L\'oggetto è necessario.',
        body: 'Il contenuto è necessario.',
        username: 'Il nome utente è necessario.',
        password: 'La password è necessaria.',
        locale: 'La lingua è necessaria.',
        name: 'Il nome è necessario.',
        token: 'Il token è necessario.'
      },
      integer: {
        native_country_id: 'L\'ID del paese deve essere un numero.',
        htms_min: 'Il valore minimo di HTMS deve essere un numero.',
        htms_max: 'Il valore massimo di HTMS deve essere un numero.',
        htms28_min: 'Il valore minimo di HTMS28 deve essere un numero.',
        htms28_max: 'Il valore massimo di HTMS28 deve essere un numero.',
        tsi_min: 'Il valore minimo del TSI deve essere un numero.',
        tsi_max: 'Il valore massimo del TSI deve essere un numero.'
      },
      numeric: {
        age_min: 'L\'età minima deve essere un numero.',
        age_max: 'L\'età massima deve essere un numero.',
        salary_min: 'Lo stipendio minimo deve essere un numero.',
        salary_max: 'Lo stipendio massimo deve essere un numero.',
        price_min: 'Il prezzo minimo deve essere un numero.',
        price_max: 'Il prezzo massimo deve essere un numero.'
      },
      required: {
        subject: 'L\'oggetto è necessario.',
        body: 'Il contenuto è necessario.',
        username: 'Il nome utente è necessario.',
        password: 'La password è necessaria.',
        current_password: 'La password attuale è necessaria.',
        new_password: 'La nuova password è necessaria.',
        email: 'L\'indirizzo email è necessario.',
        token: 'Il token è necessario.',
        endpoint: 'Il parametro \'endpoint\' è necessario.',
        url: 'Il parametro \'URL\' è necessario.',
        oauth_token: 'Il parametro \'oauth_token\' è necessario.',
        oauth_verifier: 'Il parametro \'oauth_verifier\' è necessario.'
      },
      max: {
        age_min: 'L\'età minima deve essere inferiore a 100.',
        age_max: 'L\'età massima deve essere inferiore a 100.',
        htms_min: 'Il valore minimo di HTMS deve essere inferiore a 12 000.',
        htms_max: 'Il valore massimo di HTMS deve essere inferiore a 12 000.',
        htms28_min: 'Il valore minimo HTMS28 deve essere inferiore a 12 000.',
        htms28_max: 'Il valore massimo HTMS28 deve essere inferiore a 12 000.',
        tsi_min: 'Il TSI minimo deve essere inferiore a 16 000 000.',
        tsi_max: 'Il TSI massimo deve essere inferiore a 16 000 000.',
        subject: 'L\'oggetto contiene troppi caratteri.',
        username: 'Il nome utente contiene troppi caratteri.',
        email: 'L\'indirizzo email contiene troppi caratteri.',
        locale: 'La lingua contiene troppi caratteri.',
        name: 'Il nome contiene troppi caratteri.',
        token: 'Il token contiene troppi caratteri.',
        NT_player: 'Il valore giocatore convocato in nazionale deve essere inferiore a 2 (\'@:player.levels.national_team[2]\').',

        string: {
          subject: '@:api.validation.max.subject',
          username: '@:api.validation.max.username',
          email: '@:api.validation.max.email',
          locale: '@:api.validation.max.locale',
          name: '@:api.validation.max.name',
          token: '@:api.validation.max.token',
          NT_player: '@:api.validation.max.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.max.age_min',
          age_max: '@:api.validation.max.age_max',
          htms_min: '@:api.validation.max.htms_min',
          htms_max: '@:api.validation.max.htms_max',
          htms28_min: '@:api.validation.max.htms28_min',
          htms28_max: '@:api.validation.max.htms28_max',
          tsi_min: '@:api.validation.max.tsi_min',
          tsi_max: '@:api.validation.max.tsi_max',
          NT_player: '@:api.validation.max.NT_player'
        }
      },
      min: {
        password: 'La password non contiene abbastanza caratteri.',
        age_min: 'L\'età minima deve essere superiore a 17.',
        age_max: 'L\'età massima deve essere superiore a 17.',
        native_country_id: 'L\'ID del paese deve essere superiore a 0.',
        htms_min: 'Il valore minimo di HTMS deve essere un numero positivo.',
        htms_max: 'Il valore massimo di HTMS deve essere un numero positivo.',
        htms28_min: 'Il valore minimo di HTMS28 deve essere superiore a -12 000.',
        htms28_max: 'Il valore massimo di HTMS28 deve essere superiore a -12 000.',
        tsi_min: 'Il TSI minimo deve essere un numero positivo.',
        tsi_max: 'Il TSI massimo deve essere un numero positivo.',
        salary_min: 'Lo stipendio minimo deve essere un numero positivo.',
        salary_max: 'Lo stipendio massimo deve essere un numero positivo.',
        price_min: 'Il prezzo minimo deve essere un numero positivo.',
        price_max: 'Il prezzo massimo value deve essere un numero positivo.',
        NT_player: 'Il valore giocatore convocato in nazionale deve essere superiore 0 (\'@:player.levels.national_team[0]\').',

        string: {
          password: '@:api.validation.min.password',
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        },

        numeric: {
          age_min: '@:api.validation.min.age_min',
          age_max: '@:api.validation.min.age_max',
          native_country_id: '@:api.validation.min.native_country_id',
          htms_min: '@:api.validation.min.htms_min',
          htms_max: '@:api.validation.min.htms_max',
          htms28_min: '@:api.validation.min.htms28_min',
          htms28_max: '@:api.validation.min.htms28_max',
          tsi_min: '@:api.validation.min.tsi_min',
          tsi_max: '@:api.validation.min.tsi_max',
          salary_min: '@:api.validation.min.salary_min',
          salary_max: '@:api.validation.min.salary_max',
          price_min: '@:api.validation.min.price_min',
          price_max: '@:api.validation.min.price_max',
          NT_player: '@:api.validation.min.NT_player'
        }
      },
      email: {
        email: 'Il campo indirizzo email deve contenire un indirizzo email valido.'
      },
      url: {
        url: 'Il parametro \'URL\' deve contenire un URL valido.'
      },
      in: {
        endpoint: 'Il parametro \'endpoint\' deve essere valido.',
        form_min: 'La forma minima deve essere compresa tra 1 (\'@:player.levels.skill[1]\') e 8 (\'@:player.levels.skill[8]\').',
        form_max: 'La forma massima value deve essere compresa tra 1 (\'@:player.levels.skill[1]\') e 8 (\'@:player.levels.skill[8]\').',
        agreeability_min: 'La simpatia minima deve essere compresa tra 0 (\'@:player.levels.agreeability[0]\') e 5 (\'@:player.levels.agreeability[5]\').',
        agreeability_max: 'La simpatia massima deve essere compresa tra 0 (\'@:player.levels.agreeability[0]\') e 5 (\'@:player.levels.agreeability[5]\').',
        aggressiveness_min: 'L\'aggressività minima deve essere compresa tra 0 (\'@:player.levels.aggressiveness[0]\') e 5 (\'@:player.levels.aggressiveness[5]\').',
        aggressiveness_max: 'L\'aggressività massima deve essere compresa tra 0 (\'@:player.levels.aggressiveness[0]\') e 5 (\'@:player.levels.aggressiveness[5]\').',
        honesty_min: 'La condotta morale minima deve essere compresa tra 0 (\'@:player.levels.honesty[0]\') e 5 (\'@:player.levels.honesty[5]\').',
        honesty_max: 'La condotta morale massima deve essere compresa tra 0 (\'@:player.levels.honesty[0]\') e 5 (\'@:player.levels.honesty[5]\').',
        experience_min: 'L\'esperienza minima deve essere compresa tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        experience_max: 'L\'esperienza massima deve essere compresa tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        leadership_min: 'Il carisma minimo deve essere compreso tra 1 (\'@:player.levels.skill[1]\') e 8 (\'@:player.levels.skill[8]\').',
        leadership_max: 'Il carisma massimo deve essere compreso tra 1 (\'@:player.levels.skill[1]\') e 8 (\'@:player.levels.skill[8]\').',
        stamina_skill_min: 'La resistenza minima deve essere compresa tra 0 (\'@:player.levels.skill[0]\') e 9 (\'@:player.levels.skill[9]\').',
        stamina_skill_max: 'La resistenza massima deve essere compresa tra 0 (\'@:player.levels.skill[0]\') e 9 (\'@:player.levels.skill[9]\').',
        keeper_skill_min: 'Il minimo di parate deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        keeper_skill_max: 'Il massimo di parate deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_min: 'Il minimo di regia deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        playmaker_skill_max: 'Il massimo di regia deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_min: 'Il minimo di attacco deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        scorer_skill_max: 'Il massimo di attacco deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        passing_skill_min: 'Il minimo di passaggi deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        passing_skill_max: 'Il massimo di passaggi deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        winger_skill_min: 'Il minimo di cross deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        winger_skill_max: 'Il massimo di cross deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        defender_skill_min: 'Il minimo di difesa deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        defender_skill_max: 'Il massimo di difesa deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_min: 'Il minimo di calci piazzati devo essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').',
        set_pieces_skill_max: 'Il massimo di calci piazzati deve essere compreso tra 0 (\'@:player.levels.skill[0]\') e 25 (\'@:player.levels.skill[25]\').'
      },
      exists: {
        user_id: 'L\'utente non esiste più.'
      },
      boolean: {
        promoted: 'Il parametro \'promoted\' deve essere vero o falso.',
        bookmarked: 'Il parametro \'bookmarked\' deve essere vero o falso.',
        ignored: 'Il parametro \'ignored\' deve essere vero o falso.',
        notifications_email: 'Il parametro \'notifications_email\' deve essere vero o falso.',
        notifications_desktop: 'Il parametro \'notifications_desktop\' deve essere vero o falso.'
      },
      array: {
        specialties: 'Le specialità devono contenete un intervallo di valori.'
      }
    },
    unknown: {
      general: 'È avvenuto un errore inaspettato. Aggiorna la pagina.'
    },
    network: {
      general: 'Stai incontrando problemi di connettività.',
      maintenance: 'Manutenzione in corso. Riprova tra qualche minuto.'
    },
    denied: {
      access: 'Accesso negato.'
    },
    expired: {
      token: 'Il Token di autenticazione è scaduto.',
      session: 'L\'utente non è autenticato.'
    },
    failed: {
      registration: 'Imposssibile completare la registrazione.',
      user_details: 'Impossibile recuperare i dettagli dell\'utente.',
      player_details: 'Impossibile recuperare i dettagli del giocatore.',
      filter_fetch: 'Impossibile trovare nuovi giocatori.',
      password_update: 'La password non è stata aggiornata.',
      chpp_connection: 'Impossibile stabilire la connessionne CHPP.',
      chpp_authorization: 'Impossibile inizializzare l\'autorizzazione CHPP.'
    },
    invalid: {
      credentials: 'Credenziali non valide.',
      token: 'Token di autenticazione non valido.',
      request: 'Richiesta non valida.'
    },
    limit: {
      filters: 'Hai superato il numero massimo di filtri che puoi creare.'
    },
    missing: {
      token: 'Token di autenticazione è mancante.'
    },
    conflict: {
      username: 'Nome utente già utilizzato.',
      username_password: 'Nome utente e/o indirizzo email già utilizzato.'
    }
  }
}
